// Vanilla Libraries
import 'css.escape';
import Dotdotdot from 'dotdotdot-js/dist/dotdotdot.es6.js';
import 'mmenu-js';
import moment from 'moment';
import 'moment-timezone';
import 'magnific-popup';
import 'waypoints/lib/noframework.waypoints';
import 'waypoints/lib/jquery.waypoints';
import 'waypoints/lib/shortcuts/inview';
import 'jquery.counterup';

import Swiper from 'swiper';
import {Navigation, Pagination, Autoplay, EffectFade, EffectCoverflow} from 'swiper/modules';

import * as Sentry from '@sentry/browser';
Sentry.init({
  dsn: sentryDsn,
  integrations: [
    new Sentry.Integrations.Breadcrumbs({
      console: false,
    }),
  ],
});

// JQuery & Plugins
import 'jquery';

// Beamstyle - Jquery Plugins
import 'jquery.ajaxrest';
import 'jquery.ajaxrestform';
import 'jquery.infinitescroll';

var jlib = {
  myCore: {
    initialize: function() {
      jlib.myCore.dateTime.initialize();
    },
    dateTime: {
      initialize: function() {
        var dateTimeFormat = 'YYYY-MM-DD HH:mm:ss',
          browserDateTimeOnLoad = moment()
            .tz(myCore.dateTime.serverTimezone)
            .format(dateTimeFormat);

        myCore.dateTime.browserDateTimeClockDrift = moment(myCore.dateTime.serverDateTimeOnLoad, dateTimeFormat).diff(
          browserDateTimeOnLoad,
          'seconds',
        );

        function refreshServerDateTimeNow() {
          var browserDateTimeNow = moment()
              .tz(myCore.dateTime.serverTimezone)
              .format(dateTimeFormat),
            timeElapsedSinceLoad = moment(browserDateTimeNow, dateTimeFormat).diff(browserDateTimeOnLoad, 'seconds');

          myCore.dateTime.serverDateTimeNow = moment(myCore.dateTime.serverDateTimeOnLoad, dateTimeFormat)
            .add(timeElapsedSinceLoad, 'seconds')
            .format(dateTimeFormat);
          setTimeout(refreshServerDateTimeNow, 1000);
        }
        refreshServerDateTimeNow();
      },
      methods: {
        getServerTimezone: function() {
          return myCore.dateTime.serverTimezone;
        },
        getServerDateTimeOnLoad: function() {
          return myCore.dateTime.serverDateTimeOnLoad;
        },
        getServerDateTimeNow: function() {
          return myCore.dateTime.serverDateTimeNow;
        },
        getBrowserDateTimeClockDrift: function() {
          return myCore.dateTime.browserDateTimeClockDrift;
        },
      },
    },
  },
  helpers: {
    link: function(href, target) {
      target = target === undefined ? '_self' : target;
      window.open(href, target);
    },
    getFilenameWithoutExtension: function(filename) {
      return filename.replace(/\.[^/.]+$/, '');
    },
  },
  ajaxRest: {
    initialize: function() {
      // Form for "edit"
      $('.ajax-edit').ajaxRestForm({
        // Passing in the RESPONSES_BY_STATUS_CODES
        RESPONSES_BY_STATUS_CODES: RESPONSES_BY_STATUS_CODES,

        // Overriding some common callbacks.  We pass in "true" for recursive merge, and {} to ensure the objects we pass in do not get modified itself.
        callbacks: $.extend(true, {}, jlib.ajaxRest.commonCallbacks, {
          // Proper response callbacks
          success: function(jqXHR) {
            jlib.ajaxRest.commonCallbacks.success(jqXHR);
            var $form = $(this),
              successRedirectUrl = $form.data('success-redirect');
            jlib.helpers.link(successRedirectUrl);
          },
        }),
      });

      // Form for "create"
      $('.ajax-create').ajaxRestForm({
        // Passing in the RESPONSES_BY_STATUS_CODES
        RESPONSES_BY_STATUS_CODES: RESPONSES_BY_STATUS_CODES,

        // Overriding some common callbacks.  We pass in "true" for recursive merge, and {} to ensure the objects we pass in do not get modified itself.
        callbacks: $.extend(true, {}, jlib.ajaxRest.commonCallbacks, {
          // Proper response callbacks
          success: function(jqXHR) {
            jlib.ajaxRest.commonCallbacks.success(jqXHR);
            var $form = $(this),
              successRedirectUrl = $form.data('success-redirect');
            jlib.helpers.link(successRedirectUrl);
          },
        }),
      });
    },
    helpers: {
      displayErrors: function($form, errors) {
        $.each(errors, function(field, message) {
          var $field = $form.find('.field.' + CSS.escape(field, { isIdentifier: true })),
            $errorMessage = $field.find('.error-message');
          $errorMessage.text(message);
          $field.addClass('error');
        });
      },
    },
    commonCallbacks: {
      // Before AJAX request
      beforeSend: function(resolve, reject) {
        console.log('beforeSend');
        let $body = $('body');
        $body.addClass('request-pending-overlay-active');
        // $.LoadingOverlay('show');
        resolve();
      },

      // After all callback
      complete: function (jqXHR) {
        let $body = $('body');
        $body.removeClass('request-pending-overlay-active');
      },

      // Connectivity error callbacks
      userAborted: function(jqXHR) {
        console.log('userAborted');
      },
      responseTimedOut: function(jqXHR) {
        console.log('responseTimedOut');
      },

      // Parser error callbacks
      parserError: function(jqXHR) {
        console.log('parserError');
      },

      // Proper response callbacks
      success: function(jqXHR) {
        console.log('success');
      },
      requestMalformed: function(jqXHR) {
        console.log('requestMalformed');
      },
      loginRequired: function(jqXHR) {
        console.log('loginRequired');
        var sessionTimedOutRedirectUrl =
          siteUrl + 'console/connect/login?redirectUrl=' + encodeURIComponent(currentRouteWithQueryString);
        jlib.helpers.link(sessionTimedOutRedirectUrl);
      },
      notEnoughAccess: function(jqXHR) {
        console.log('notEnoughAccess');
      },
      notFound: function(jqXHR) {
        console.log('notFound');
      },
      unsupportedFormat: function(jqXHR) {
        console.log('unsupportedFormat');
      },
      validationError: function(jqXHR) {
        var $form = $(this),
          data = jqXHR.responseJSON,
          errors = data.errors;
        jlib.ajaxRest.helpers.displayErrors($form, errors);

        let $errorObject = $form.find('.error').first();
        if( $errorObject.length !== 0 ) {
          let position = $errorObject.offset().top;
          $(document).scrollTop(position);
        }
      },
      tooManyRequests: function(jqXHR) {
        console.log('tooManyRequests');
      },
      unexpectedError: function(jqXHR) {
        console.log('unexpectedError');
      },
      default: function(jqXHR) {
        console.log('default');
      },
    },
  },
};

$(document).ready(function(e) {
  jlib.myCore.initialize();
  jlib.ajaxRest.initialize();

  // Initialize multiline truncate (ellipsis / clamp) effect
  $('.multiline-truncate').each(function() {
    let $text = $(this);
    let maxHeight = parseFloat($text.css('max-height'));
    let maximumLines = parseInt($text.data('maximum-lines') ?? 1);
    let lineHeight = parseFloat($text.css('line-height'));

    // If there is no max-height css attribute for the element, we make use of maximum-lines and line-height to calculate it
    if(isNaN(maxHeight)) {
      $text.css('max-height', lineHeight * maximumLines);
      $text.css('overflow', 'hidden');
    }

    let helpers = {
      dotdotdot: function() {
        new Dotdotdot( $text[0], {
          truncate: 'letter',
          watch: true,
        });
      }
    };

    helpers.dotdotdot();
  });

  // Simple element animation
  $('.animation').each(function() {
    let $this = $(this);
    let repeatable = $this.data('repeatable') !== undefined ? $this.data('repeatable') : false;
    let timer = null;

    let waypoint = new Waypoint.Inview({
      element: $this[0],
      enter: function() {
        // Timer set, just in case if a page gets reloaded in a position beyond the element, timer can be cleared out immediately as if the event has never happened.
        timer = setTimeout(function() {
          $this.addClass('animate');
        }, 200);
      },
      exited: function() {
        // Clearing any active timers.
        clearTimeout(timer);
        if (repeatable) {
          $this.removeClass('animate');
        }
      },
    });
  });

  // Infinite Scroll
  $('.infinite-scroll').each(function() {
    let $this = $(this);
    let autoFetchThreshold = parseInt($this.data('auto-fetch-threshold') ?? 0);
    let resetFetchedCount = $this.data('reset-fetched-count');

    $this.infiniteScroll({
      autoFetchThreshold: autoFetchThreshold,
      resetFetchedCount: resetFetchedCount,
      ajaxRestCallbacks: $.extend(true, {}, jlib.ajaxRest.commonCallbacks, {
        success: function(jqXHR) {
          jlib.ajaxRest.commonCallbacks.success(jqXHR);
          console.log('ajaxRestCallbacks.success', $(this), jqXHR);
        },
      }),
      callbackEntityAdded: function($entity) {
        console.log('callbackEntityAdded', $(this), $entity);
      },
    });
  });

  // Block Carousel
  $('.carousel').each(function() {
    var $blockCarousel = $(this),
      $blockCarousel_swiper = $blockCarousel.find('.swiper-container'),
      $blockCarousel_pagination = $blockCarousel.find('.pagination'),
      $blockCarousel_navigation = $blockCarousel.find('.navigation'),
      $blockCarousel_navigation_buttonPrevious = $blockCarousel_navigation.find('.button-previous'),
      $blockCarousel_navigation_buttonNext = $blockCarousel_navigation.find('.button-next');

    var loop = $blockCarousel.data('loop'),
      autoplay = $blockCarousel.data('autoplay'),
      breakpoints = $blockCarousel.data('breakpoints'),
      effect = $blockCarousel.data('effect');

    var swiperOptions = {
      modules: [Navigation, Pagination, Autoplay],
      direction: 'horizontal',
      effect: effect !== undefined ? effect : false,
      loop: loop !== undefined ? loop : false,
    };

    // If autoplay is defined and not false
    if (autoplay !== undefined && autoplay) {
      swiperOptions = $.extend(swiperOptions, {
        autoplay: {
          disableOnInteraction: false,
          delay: parseInt(autoplay),
        },
      });
    }

    // If breakpoints is defined
    if (breakpoints !== undefined) {
      swiperOptions = $.extend(swiperOptions, {
        breakpoints: breakpoints[0],
      });
    }

    // If there are navigation elements (< and >)
    if ($blockCarousel_navigation.length) {
      swiperOptions = $.extend(swiperOptions, {
        navigation: {
          prevEl: $blockCarousel_navigation_buttonPrevious[0],
          nextEl: $blockCarousel_navigation_buttonNext[0],
        },
      });
    }

    // If there is a pagination element (bullets)
    if ($blockCarousel_pagination.length) {
      swiperOptions = $.extend(swiperOptions, {
        pagination: {
          el: $blockCarousel_pagination[0],
          clickable: true,
        },
      });
    }

    var mySwiper = new Swiper($blockCarousel_swiper[0], swiperOptions);
  });

  // Photo Swiper
  var coverflowSwiper = new Swiper(".coverflow-swiper", {
    modules: [Navigation, Pagination, Autoplay, EffectCoverflow],
    effect: 'coverflow',
    centeredSlides: true,
    grabCursor: true,
    initialSlide: 1,
    slidesPerView: 3,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: false,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    slideToClickedSlide: true,
  });

  // Tabs Menu - Swiper
  var tabsSwiper = new Swiper('.tabs-swiper-container', {
    effect: 'slide',
    autoHeight: true,
    slidesPerView: 1,
    paginationClickable: true,
    spaceBetween: 0,
    autoHeight: true,
    loop: false,
    followFinger: true,
  });

  $('.tabs-menu').each(function(e) {
    var $this = $(this),
      swiper = tabsSwiper,
      $tabsList = $this.find('.tabs-list'),
      $tabsList_items = $tabsList.find('li'),
      $tabsList_initPosition = $tabsList.find('li.active');

    var helpers = {
      initSwiperByTabPosition: function() {
        var index = $tabsList_initPosition.data('index');
        swiper.slideTo(index);
        swiper.on('transitionEnd', helpers.syncTabWithSwiper);
      },
      syncTabWithSwiper: function() {
        var swiperPos = swiper.activeIndex,
          target = $tabsList.find('li[data-index=' + swiperPos + ']');
        $tabsList_items.removeClass('active');
        target.addClass('active');
      },
    };

    helpers.initSwiperByTabPosition();

    $tabsList_items.each(function() {
      var $this = $(this);

      $this.mouseenter(function() {
        $this.addClass('on-hover-active');
      });

      $this.mouseleave(function() {
        $this.removeClass('on-hover-active');
      });

      $this.click(function() {
        var index = $this.data('index');
        swiper.slideTo(index);
        $tabsList_items.removeClass('active');
        $this.addClass('active');
      });
    });
  });
                        
  // Tab Switch
  $('.tab-switch').each(function() {
    let $this = $(this);
    let $select = $this.find('select');
    let $radio = $this.find('input[type="radio"]');

    let tabCollectionSelector = $this.data('tab-collection-selector');
    let $tabCollection = $(tabCollectionSelector);
    let $tabCollection_tabs = $tabCollection.children('.tab');

    let helpers = {
      displayActiveTab: function($element) {
        let switchValue =
          ($element.is('select') && $element.find(':selected').data('target')) ||
          ($element.is('input:radio') && $element.data('target')) ||
          $element.val();
        let activeTabSelector = switchValue;

        $tabCollection_tabs
          .removeClass('active')
          .filter(activeTabSelector)
          .addClass('active');
      },
    };

    $select.change(function(e) {
      helpers.displayActiveTab($(this));
    });

    $radio.change(function(e) {
      let $this = $(this);
      let isChecked = $this.prop('checked');

      if (!isChecked) {
        return;
      }

      helpers.displayActiveTab($(this));
    });

    $select.trigger('change');
    $radio.trigger('change');
  });

  // Add "scroll-top" class when scroll is at top of page in the browser
  let $html = $('html');
  let offset = 59;
  $(document)
    .scroll(function() {
      if ($(this).scrollTop() <= offset) {
        $html.removeClass('scroll-not-top');
        $html.addClass('scroll-top');
      } else {
        $html.removeClass('scroll-top');
        $html.addClass('scroll-not-top');
      }
    })
    .trigger('scroll');

  // Click event to scroll to top
  $('.scroll-to-top').click(function(e) {
    e.preventDefault();
    $('html, body').animate({ scrollTop: 0 }, 100);
  });

  // Mobile menu
  let menu = new Mmenu( "#mobile-nav", {
    navbar: {
      add: false,
    },
    offCanvas: {
      position: "right-front",
    },
    hooks: {
      "close:after": ( panel ) => {
        const menuApi = menu.API;
        let $homePanel = $('.mm-panel:first-child');
        menuApi.openPanel($homePanel[0]);
      },
    },
  });

  $('#mobile-nav').on('click', '.mm-listitem a:first-child', function(e) {
    menu.API.close();
  });

  // Animated Hamburgers Menu
  $('#hamburger-icon').hover(function(){
    $(this).toggleClass('open');
  });

  // Header - Show head background when refresh the browser
  if ($(window).scrollTop() > 0) {
    $('#header').addClass('active');
  } else {
     $('#header').removeClass('active');
  }

  // Header Animation
  $(window).scroll(function() {
    var height = $(window).scrollTop();
    if(height > 50) {
      $('#header').addClass('active');
    } else {
      $('#header').removeClass('active');
    }
  });

  // Lightbox
  $('.lightbox-trigger').each(function() {
    let $this = $(this);
    let $id = $this.attr('href');
    let hashId = ($this.data('hash-id') !== undefined) ? $this.data('hash-id') : undefined;
    let closeOnBgClick = ($this.data('close-on-bg-click') !== undefined) ? $this.data('close-on-bg-click') : true;
    let showCloseBtn = ($this.data('show-close-btn') !== undefined) ? $this.data('show-close-btn') : true;
    let enableEscapeKey = ($this.data('enable-escape-key') !== undefined) ? $this.data('enable-escape-key') : true;
    let fixedContentPos = ($this.data('fixed-content-pos') !== undefined) ? $this.data('fixed-content-pos') : 'auto';
    let fixedBgPos = ($this.data('fixed-bg-pos') !== undefined) ? $this.data('fixed-bg-pos') : 'auto';
    let autoPopup = ($this.data('auto-popup') !== undefined) ? $this.data('auto-popup') : false;
    let autoPopupDelay = ($this.data('auto-popup-delay') !== undefined) ? $this.data('auto-popup-delay') : 0;
    let autoPopupIgnoreDuration = ($this.data('auto-popup-ignore-duration') !== undefined) ? $this.data('auto-popup-ignore-duration') : 0;
    let expireDatetime;
    let autoPopupDelayInMilliseconds = 0;

    if ($.isNumeric(autoPopupIgnoreDuration) === true && autoPopupIgnoreDuration > 0){
      expireDatetime = new Date(new Date().getTime() + autoPopupIgnoreDuration * 60 * 1000); // Minutes
    }

    let config = {
      items:{
        src: $id,
        type: 'inline',
      },
      type: 'inline',
      closeOnBgClick: closeOnBgClick,
      showCloseBtn: showCloseBtn,
      enableEscapeKey: enableEscapeKey,
      fixedContentPos: fixedContentPos,
      fixedBgPos: fixedBgPos,
      callbacks: {
        open: function() {
          $(this.content).find('.current-url').each(function(){
            $this = $(this);
            let currentRoute = currentRouteWithQueryString;
            if (currentRoute.includes('token-invalid')) {
              currentRoute = "/";
            }
            if (typeof hashId !== 'undefined' && typeof hashId !== 'null') {
              currentRoute += "#" + hashId;
            }
            $this.val(currentRoute);
          });
          $(this.content).find('.popup-close').each(function(index) {
            let $this = $(this);
            $this.on('click', function(e) {
              $.magnificPopup.close();
            });
          });
          if (expireDatetime !== undefined){
              Cookie.set('ignore-popup-' + $(this.content).attr('id'), true, { expires: expireDatetime });
          }
        },
        change: function(){
          $(this.content).find('.popup-close').each(function(index) {
            let $this = $(this);
            $this.on('click', function(e) {
              $.magnificPopup.close();
            });
          });
        },
      }
    };

    if (autoPopup == true){
      let isClick = true;
      if (expireDatetime !== undefined){
        let ignoreId = ($this.data('ignore-id') !== undefined) ? $this.data('ignore-id') : 0;
        let cookie = Cookie.get('ignore-popup-' + ignoreId);
        if (cookie === 'true'){
          isClick = false;
        } 
      }

      if (isClick) {
        setTimeout(function(){
          if (! $.magnificPopup.instance.isOpen){
            $.magnificPopup.open(config);
          }
        },autoPopupDelayInMilliseconds);
      }
    }

    $this.magnificPopup(config);
  });

  // Interactive Map - New version testing //
  $('.test-interactive-map').each(function() {
    let $this = $(this);
    let $this_location = $this.find('.location ul');
    let $this_marker = $this.find('.marker');
    let $this_map = $this.find('.map');
    
    $(".targetDiv").hide();

    $this_location.find('li a').hover(function() {
      let $thisLocation = $(this);
      let href = $thisLocation.attr('href');
      $this.find(href).toggleClass('active');
      // console.log(href);
      // console.log('hover', $this[0], $this.attr('class'));
    });

    $this_marker.find('a').hover(function() {
      let $this_marker = $(this);
      let href = $this_marker.attr('href');
      $this.find(href).toggleClass('active');
    });

    $('.popup-trigger').click(function(e){
      e.preventDefault();
      //jQuery('.targetDiv').hide('.map');
      $('#'+$(this).attr('target')).slideToggle();
    }); 

    $('.map-popup-close').click(function(){
      $('.targetDiv').hide('.map');
    }); 
  });

  // Interactive Map //
  $('.interactive-map').each(function() {
    let $this = $(this);
    let $this_location = $this.find('.location ul');
    let $this_marker = $this.find('.marker');
    let $this_map = $this.find('.map');

    $(".targetDiv").hide();

    $this_location.find('li a').hover(function() {
      let $thisLocation = $(this);
      let target = $thisLocation.attr('target');
      //console.log(target);
      $this.find('.'+$(this).attr('target')).toggleClass('active');
    });

    $this_marker.find('a').hover(function() {
      let $this_marker = $(this);
      let target = $this_marker.attr('target');
      $this.find('.'+$(this).attr('target')).toggleClass('active');
    });

    $('.popup-trigger').click(function(){
      $('#'+$(this).attr('target')).slideToggle();
    }); 

    $('.map-popup, .map-popup-close').click(function(){
      $('.targetDiv').hide('.map');
    }); 
  });

  // Milestones
  $('.offerItemTitle').click(function(){
    $(this).parents('.offerslide').children('.offerItem').removeClass('active');
    $(this).parents('.offerslide').children('.offerItem').children('.offerItemTitle').removeClass('hide');
    $(this).parent('.offerItem').addClass('active');
    $(this).addClass('hide');
  });

  $('.milestones').each(function(e) {
    var headerHeight = $('header').outerHeight(); // Target your header navigation here
    $("#milestones-position").click(function(e) {
      var targetHref   = $(this).attr('href');
      console.log(targetHref);
      $('html, body').animate({
          scrollTop: $(targetHref).offset().top - 60 // Add it to the calculation here
      }, 0);
      e.preventDefault();
    });
  });

  //Number Counter //
  $('.counter').counterUp({
    delay: 10,
    time: 2000
  });

  //Video hosted on the Harrow streaming platform
  var vars = {clip_id:"8wao4nxr05s8",transparent:"true",pause:"1",repeat:"",bg_color:"#ffffff",fs_mode:"2",no_controls:"",start_img:"0",start_volume:"34",close_button:"",brand_new_window:"1",auto_hide:"1",stretch_video:"",player_align:"NONE",offset_x:"0",offset_y:"0",player_color_ratio:0.6,skinAlpha:"50",colorBase:"#250864",colorIcon:"#ffffff",colorHighlight:"#7f54f8",direct:"false",is_responsive:"true",viewers_limit:0,cc_position:"bottom",cc_positionOffset:70,cc_multiplier:0.03,cc_textColor:"#ffffff",cc_textOutlineColor:"#ffffff",cc_bkgColor:"#000000",cc_bkgAlpha:0.1,aspect_ratio:"16:9",play_button:"1",play_button_style:"pulsing",sleek_player:"1",auto_play:"0",auto_play_type:"unMute",floating_player:"none"};
  var svp_player = new SVPDynamicPlayer("svp_player8wao4nxr05s8", "", "100%", "100%", {use_div:"svp_player8wao4nxr05s8",skin:"3"}, vars);
  svp_player.execute();
});